html, body {
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: #ebe8e7;
	font-size: 16px;
}

@font-face {
	font-family: 'PACIFICO';
	font-style: normal;
	font-weight: normal;
	src: url('assets/Pacifico-Regular.ttf');
}

.pacifico {
	font-family: 'PACIFICO';
}

@font-face {
	font-family: 'LOBSTER';
	font-style: normal;
	font-weight: normal;
	src: url('assets/Lobster-Regular.ttf');
}

.lobster {
	font-family: 'LOBSTER';
}

@font-face {
	font-family: 'ROBOTO';
	font-style: normal;
	font-weight: normal;
	src: url('assets/Roboto-Regular.ttf');
}

.roboto {
	font-family: 'ROBOTO';
}

a {
	text-decoration: none;
	color: black;
}