.Contact {
	height: 100%;
	width: 100%;

	/* set to 100% of the viewheight and width */
	height: 100vh;
	width: 100vw;

	background-repeat: no-repeat;
	background-size: 100%;

	background-color: #ebe8e7; 
}

.Form {
	width:100%;
	height: 89%;
	border-style: none;
	margin-bottom: 0px;
	padding-bottom: 0px;
}